
@each $color-key, $color-value in $colors {
	.checkbox-#{$color-key}.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
		border-color: color($color-key, 'hover-bg');
		background: color($color-key, 'hover-bg');
	}
	.checkbox-#{$color-key}.p-checkbox .p-checkbox-box.p-highlight {
		border-color: color($color-key, 'bg');
		background: color($color-key, 'bg');
	}
	.checkbox-#{$color-key}.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
		border-color: color($color-key, 'bg');
	}
	.checkbox-#{$color-key}.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
		box-shadow: 0 0 0 0.2rem color($color-key, 'transparent-bg');
		border-color: color($color-key, 'bg');
	}
}

.p-checkbox-label:not(.p-disabled) {
	cursor: pointer;
}

.p-checkbox-box {
	flex-shrink: 0;
}

.p-field-checkbox > label {
	margin-bottom: 0;
}

.min-h-field-checkbox {
	min-height: 4rem;
}

.field.field-checkbox {
	display: flex;
  align-items: end;
  margin-bottom: 1.5rem;
}
