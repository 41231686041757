
@media screen and (min-width: $xxl) {
    .p-xxl-1,
    .p-xxl-2,
    .p-xxl-3,
    .p-xxl-4,
    .p-xxl-5,
    .p-xxl-6,
    .p-xxl-7,
    .p-xxl-8,
    .p-xxl-9,
    .p-xxl-10,
    .p-xxl-11,
    .p-xxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .p-xxl-1 {
        width: 8.3333%;
    }

    .p-xxl-2 {
        width: 16.6667%;
    }

    .p-xxl-3 {
        width: 25%;
    }

    .p-xxl-4 {
        width: 33.3333%;
    }

    .p-xxl-5 {
        width: 41.6667%;
    }

    .p-xxl-6 {
        width: 50%;
    }

    .p-xxl-7 {
        width: 58.3333%;
    }

    .p-xxl-8 {
        width: 66.6667%;
    }

    .p-xxl-9 {
        width: 75%;
    }

    .p-xxl-10 {
        width: 83.3333%;
    }

    .p-xxl-11 {
        width: 91.6667%;
    }

    .p-xxl-12 {
        width: 100%;
    }

    .p-xxl-offset-12 {
        margin-left: 100%;
    }

    .p-xxl-offset-11 {
        margin-left: 91.66666667%;
    }

    .p-xxl-offset-10 {
        margin-left: 83.33333333%;
    }

    .p-xxl-offset-9 {
        margin-left: 75%;
    }

    .p-xxl-offset-8 {
        margin-left: 66.66666667%;
    }

    .p-xxl-offset-7 {
        margin-left: 58.33333333%;
    }

    .p-xxl-offset-6 {
        margin-left: 50%;
    }

    .p-xxl-offset-5 {
        margin-left: 41.66666667%;
    }

    .p-xxl-offset-4 {
        margin-left: 33.33333333%;
    }

    .p-xxl-offset-3 {
        margin-left: 25%;
    }

    .p-xxl-offset-2 {
        margin-left: 16.66666667%;
    }

    .p-xxl-offset-1 {
        margin-left: 8.33333333%;
    }

    .p-xxl-offset-0 {
        margin-left: 0%;
    }
}
