@layer {

	html {
		font-family: var(--font-family);
		font-size: 1rem;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	* {
		min-height: 0;
		min-width: 0;
	}

	a {
		color: inherit;
		text-decoration: none;
		cursor: pointer;
	}

}
