.login-body {
	background: var(--background-color);
	color: var(--primary-text-color);
}

.login-body .login-wrapper {
	display: flex;
	height: 100vh;
	justify-content: center;
}

.login-body .login-wrapper .login-panel {
	width: 30%;
	height: 100%;
	padding: 40px 10px;
	max-width: 340px;
	// margin: auto;
	overflow-y: auto;
}

.login-body .login-wrapper .login-panel .logo {
	max-height: 90px;
	max-width: 230px;
}

.login-body .login-wrapper .login-panel .login-form {
	max-width: 250px;
	height: auto;
	margin: auto;
}

.login-body .login-wrapper .login-panel .login-form > p {
	font-weight: 600;
	margin: 0;
	color: var(--secondary-super-light-color);
	margin-bottom: 32px;
}

.login-body .login-wrapper .login-panel .login-form > p > a {
	color: var(--primary-color);
	cursor: pointer;
}

.login-body .login-wrapper .login-panel .login-form > input {
	width: 85%;
	max-width: 310px;
	margin-bottom: 20px;
}

.login-body .login-wrapper .login-panel .login-form > button {
	width: 85%;
	max-width: 310px;
}

.login-body .login-wrapper .login-panel p {
	font-weight: 600;
	margin: 0;
	color: var(--secondary-super-light-color);
}

.login-body .login-wrapper .login-panel p > a:hover {
	color: var(--primary-text-color);
}

.login-body .login-wrapper .landing {
	// width: 70%;
	flex-grow: 1;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	position: relative;
}

.login-body .login-wrapper .landing > * {

}

.login-body .login-wrapper .landing img {
	// max-width: 100%;
	// max-height: 100%;
	object-fit: cover;
	position: absolute;
	/* Position the image in the middle of its container. */
	top: -9999px;
	right: -9999px;
	bottom: -9999px;
	left: -9999px;
	margin: auto;
	/* The following values determine the exact image behaviour. */
	/* You can simulate background-size: cover/contain/etc.
	 by changing between min/max/standard width/height values.
	 These values simulate background-size: cover
	*/
	min-width: 100%;
	min-height: 100%;
}


@media (min-width: 993px) {
	.login-body .login-wrapper .login-image {
		background-size: cover;
		background-repeat: no-repeat;
		/*background-image: url("/assets/images/carbon-800x800.jpg");*/
		background-color: rgb(32, 32, 32);
	}
}

@media (max-width: 992px) {
	.login-body .login-wrapper .login-panel {
		width: 100%;
		z-index: 2;
	}

	.login-body .login-wrapper .login-image {
		display: none;
	}
}
