
media-thumb {
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		position: relative;
		max-width: 100%;
		max-height: 100%;
	}
}

.image-picker {
	border: 1px solid rgba(0,0,0,0.25);
	text-align: center;
	border-radius: 5px;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 150px;

	button {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.media-view-pdf {
	min-width: calc(100vw - 15rem);
	height: calc(100vh - 15rem);
	max-width: 80vw;
	max-height: 80vh;
	embed {
		width: 100%;
		height: calc(100vh - 15rem);
	}
}

.media-view-video {
	video {
		max-width: 80vw;
		max-height: 80vh;
	}
}

.media-view-image {
	img {
		max-width: 80vw;
		max-height: 80vh;
	}
}
