.p-fileupload-content {
	padding: 1rem;
}

.p-fileupload-thumb {
	max-width: 75px;
	width: 20%;
	min-width: 50px;
	text-align: center;
	padding: 0 1rem 0 0;
}

.p-fileupload-file-info > div {
	display: table;
	table-layout: fixed;
	width: 100%;
}
.p-fileupload-button {
	max-width: 80px;
	min-width: 32px;
}

.p-fileupload-file:not(:first-child) {
	padding-top: 5px;
}
.p-fileupload-file:not(:last-child) {
	border-bottom: 1px solid #dedede;
	padding-bottom: 5px;
}
