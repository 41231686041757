.nomenclature-item .nomenclature-buttons {
	opacity: 0;
	transition: opacity .2s;
}

.nomenclature-item:hover .nomenclature-buttons {
	opacity: 100;
}

.p-tree-container {
	max-width: 100%;
}

.p-treenode-label {
	// flex-grow: 1;
	padding: 0 5px;
	border-radius: 5px;
}

.p-treenode-label:hover {
	background: var(--menu-header-hover-bg);
}

.p-tree-wrapper {
	height: 100%;
	padding: 1px;
}

.p-treenode-content {
	position: relative;
}

.p-tree-toggler {
	margin-right: 0;
	color: var(--primary-color);
}

.inactive-tree-node {
	color: #8a8a8a;
}

.treenode-auto .p-treenode {
	height: auto !important;
}


.article-body:not(.expanded) {
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 100px;
	content: "";

	&:before {
		content:'';
		width: 100%;
		height: 100%;
		position:absolute;
		left:0;
		top:0;
		background: linear-gradient(transparent 70px, white);
		z-index: 1;

		cursor: pointer;
	}
	.p-editor-content {
		overflow: hidden;
	}
}


.article-body.expanded {
	&:before {
		display: none;
	}
}
