
@each $color-key, $color-value in $colors {
	.p-text-#{$color-key} {
		color: color($color-key, 'text');
	}
}

.monospace {
	font-family: monospace, monospace;
}

.text-capitalize {
	text-transform: capitalize !important
}
