.number-selector {
	padding:  0;
	margin: 0;
}

.number-selector li {
	padding: 0.5rem;
	display:  inline-block;
}

.number-selector li > span {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	transition: box-shadow 0.2s;
	border: 1px solid transparent;
	align-items: center;
	cursor: pointer;
	justify-content: center;
	overflow: hidden;
	position: relative;
	display: flex;
	margin: 0 auto;
}


.number-selector li > span.p-highlight {
	color: #495057;
	background: #E3F2FD;
}

.number-selector:not(.p-disabled) li span:not(.p-highlight):not(.p-disabled):hover {
	background: #e9ecef;
}
