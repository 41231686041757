.p-datatable table {
	border-collapse: collapse;
	background: #fff;
}

.p-datatable .p-datatable-thead > tr > th {
	padding: 1rem 5px;
}

.p-datatable .p-datatable-tbody > tr > td {
	padding: 1rem 5px;
	/*white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;*/
}

.p-datatable .p-datatable-tfoot > tr > th,
.p-datatable .p-datatable-tfoot > tr > td {
	border: 1px solid var(--border-color);
	font-weight: bold;
	padding: 0.5rem;
	background: #fff;
	// position: inherit;
}

.p-datatable .p-datatable-tbody > tr.inactive {
	color: #aaa;
}

.ellipsis-cell {
	max-width: 1px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.cell-grow {
	width: 100%;
}

.cell-lines-3 {
	height: 66px;
}

.p-datatable .p-datatable-thead > tr > th {
	line-height: 1em;
}

tr.disabled {
	background: var(--surface-300) !important;
}

td.disabled {
	background: var(--surface-300) !important;
}

td,
td > * {
	// position: relative;
}

table th.nowrap,
table td.nowrap {
	white-space: nowrap;
	width: 1px;
}

@each $key, $breakpoint in $breakpoints {
	@media screen and (max-width: $breakpoint) {
		table th.wrap-#{$key},
		table td.wrap-#{$key} {
			white-space: normal !important;
		}
	}
}

.col-libelle {
	min-width: 200px;
	width: 200px;
	max-width: 200px;
}

.col-libelle-min {
	min-width: 200px;
}

.col-libelle-xl {
	min-width: 300px;
	width: 300px;
	max-width: 300px;
}

.col-xl {
	width: 300px;
	max-width: 300px;
}

.col-logo {
	width: 50px;
	max-width: 50px;
	min-width: 50px;
}

.col-logo-libelle {
	width: 50px;
	max-width: 100px;
	min-width: 50px;
	logo-display {
		min-height: 35px;
		margin-bottom: 0.5rem;
	}
}

.col-media {
	width: 50px;
	max-width: 50px;
	min-width: 50px;
}

.col-actions-1 {
	width: 3rem;
}

.col-actions-2 {
	width: 7rem;
}

.col-actions-3 {
	width: 9rem;
}

.rowexpansion {
	padding: 0.5rem 0 !important;
}


.col-header {
	border: 1px solid var(--border-color);
	font-weight: bold;
	padding: 0.5rem;
	background: var(--surface-ground);
	// position: inherit;
}

tr.input {
	height: 48px;
}
tr.readonly {
	color: #bbb;
}

liste-notifications-admin {
	td {
		vertical-align: top;
	}
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
	padding: 0.5rem 0.5rem;
}

.p-datatable-autolayout table {
	table-layout: auto !important;
}

.table-scrollable-hover tr:hover {
	content: '';
	background: rgba(0,0,0,.035) !important;
	// outline: 1px solid var(--primary-color);
}

.table-scrollable-hover tr:hover td.col-header {
	background: var(--surface-200);
}

tr.group,
tr.groupe-conditions {
	background: var(--surface-ground) !important;
}

tr.segment-standard > td > span:before {
	content: '  • ';
}

td.puce > span:before {
	content: '  • ';
}

tr.challenge {

}

tr.row-warning,
tr.row-warning td,
td.cell-warning {
	background: color('warning', 'transparent-bg');
}

tr.uti-bloque td,
tr.invalid td {
	color: color('danger', 'text');
}

tr.row-danger,
tr.row-danger td,
td.cell-danger {
	background: color('danger', 'transparent-bg');
}

tr.highlight {
	background: yellow !important;
}

.cell-input {
	max-width: 100px;
}

.clear-cell {
	background: transparent !important;
	border: none !important;
}


.row-panel-header td {
	padding: 0.5rem 1rem !important;
	line-height: 2rem;
}




.p-datatable-scrollable table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
}

.p-datatable-scrollable.p-datatable-gridlines .p-datatable-thead > tr > th:not(:last-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > td:not(:last-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > td:not(:last-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > th:not(:last-child) {
    border-right-width: 0px !important;
    border-left-width: 1px !important;
}

.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > td,
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > td,
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > th {
    border-top-width: 0px !important;
    border-bottom-width: 1px !important;
}

.p-datatable-scrollable.p-datatable-gridlines .p-datatable-thead > tr > td.p-frozen-column.last-frozen-left,
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-thead > tr > th.p-frozen-column.last-frozen-left,
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > td.p-frozen-column.last-frozen-left,
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > th.p-frozen-column.last-frozen-left,
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > td.p-frozen-column.last-frozen-left,
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > th.p-frozen-column.last-frozen-left {
    border-right-width: 2px !important;
}

.p-datatable-scrollable.p-datatable-gridlines .p-datatable-thead > tr > td.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-thead > tr > th.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > td.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > th.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > td.p-frozen-column.last-frozen-left:not(:first-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > th.p-frozen-column.last-frozen-left:not(:first-child) {
    /*border-right-width: 2px !important;*/
    border-left-width: 0px !important;
}

.p-datatable-scrollable.p-datatable-gridlines .p-datatable-thead > tr > td.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-thead > tr > th.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > td.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > th.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > td.p-frozen-column.last-frozen-right:not(:last-child),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > th.p-frozen-column.last-frozen-right:not(:last-child) {
    border-left-width: 2px !important;
    border-right-width: 0px !important;
}

.p-datatable-scrollable.p-datatable-gridlines .p-datatable-thead > tr > td.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-thead > tr > th.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > td.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tbody > tr > th.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > td.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right),
.p-datatable-scrollable.p-datatable-gridlines .p-datatable-tfoot > tr > th.p-frozen-column:not(.last-frozen-left):not(.last-frozen-right) {
    border-right-width: 1px !important;
    border-left-width: 1px !important;
}

.p-frozen-column {
    border-style: solid;
    border-color: var(--border-color);
}


/* règle stupide dans le thème */
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td,
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
	border-width: 1px;
}
