/* You can add global styles to this file, and also import other style files */

@import 'styles/_variables';
// @import 'styles/theme.css';
@import 'styles/theme/theme.css';
@import 'styles/theme-fix.scss';
@import 'styles/primeflex';
@import 'styles/base';
@import 'styles/buttons';
@import 'styles/checkbox';
@import 'styles/dashboard-box';
@import 'styles/dropdown';
@import 'styles/hlina-spinner';
@import 'styles/fileupload';
@import 'styles/grid-xxl';
@import 'styles/list';
@import 'styles/login';
@import 'styles/logo';
@import 'styles/media';
@import 'styles/menu';
@import 'styles/quill';
@import 'styles/number-selector';
@import 'styles/paragraphes';
@import 'styles/table';
@import 'styles/tabs';
@import 'styles/text';
@import 'styles/layout-content';
@import 'styles/topbar';
@import 'styles/nomenclature.scss';

