.layout-content {
	padding-top: $topbar-height;
	height: 100%;
	position: relative;

}

.layout-content .main-content {
	transition: padding 250ms;
	background: var(--surface-c);
	height: 100%;
	max-height: 100%;
	padding: 1rem;
	padding-left: calc($sidebar-width + 1rem);

	display: flex;
	flex-direction: column;
}

.gz-sidebar {
	position: fixed !important;
	height: calc(100% - #{$topbar-height});
	width: $sidebar-width;
	transition: 250ms;
	z-index: 10;

	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
	background: var(--secondary-light-color);
}

site, diagnostic {
	display: block;
	height: 100%;
	display: flex;
	flex-direction: column;
}