@mixin absolute-full {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

body {
	@include absolute-full;
	margin: 0;
	padding: 0;
	background-color: var(--background-color, var(--surface-a));
	font-family: var(--font-family);
	font-weight: 400;
	color: var(--text-color);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ui-view {
	display: block;
	height: 100%;
	position: relative;
}

.main-content ui-view > * {
	// display: block;
	display: flex;
	flex-direction: column;
	height: 100%;
}

body > ui-view::before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--background-color, var(--surface-200));
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 1.5rem 0 1rem;
	font-family: inherit;
	font-weight: 600;
	line-height: 1.2;
	color: inherit
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0
}

h1 {
	font-size: 2.5rem
}

h2 {
	font-size: 2rem
}

h3 {
	font-size: 1.75rem
}

h4 {
	font-size: 1.5rem
}

h5 {
	font-size: 1.25rem
}

h6 {
	font-size: 1rem
}

p {
	line-height: 1.5;
	margin: 0 0 1rem
}

img {
	font-size: 0.5rem;
	color: var(--maskbg);
}

input[type=number] {
	-moz-appearance: textfield
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0
}

label {
	font-weight: 500;
}

textarea {
	min-height: 2.5em !important;
}

.clickable {
	cursor: pointer;
}

.p-component,
.p-inputtext,
.p-inputnumber,
.p-inputtextarea {
	font-family: var(--font-family);
}

.p-fluid .p-button {
	width: auto;
}

.p-fluid > .p-button {
	width: 100%;
}

.landing {
	height: 100%;
}

.p-inputtext:enabled:read-only:hover,
.p-inputtext:enabled:read-only:focus {
	border-color: #ced4da;
	box-shadow: none;
}

.p-inputtext:enabled:read-only {
	border-top: 1px solid transparent !important;
	border-left: 1px solid transparent !important;
	border-right: 1px solid transparent !important;
	border-radius: 0;
}

input[read-only]::placeholder,
.p-inputtext:read-only::placeholder {
	color: transparent !important;
}

.p-inputgroup .p-inputtext,
.p-fluid .p-inputgroup .p-inputtext,
.p-inputgroup .p-inputwrapper,
.p-inputgroup .p-inputwrapper > .p-component {
	width: 100%;
}

.p-inputnumber,
.p-inputnumber-input,
.p-inputtext {
	max-width: 100%;
}

.p-input-icon-left,
.p-input-icon-right {
	max-width: 100%;
}

.p-input-icon-right > .p-inputtext {
	padding-right: 1.3rem;
}

.p-input-icon-right > .p-inputtext.input-quantity {
	padding-right: 2.7rem;
}

.min-height-0 {
	min-height: 0;
}

.p-datepicker-inline {
	display: block !important;
}

.ff-reset {
	font-family: inherit;
}

.p-confirm-dialog-message {
	white-space: pre-line;
}

.p-confirm-dialog .p-dialog-content {
	align-items: start !important;
}

.p-dialog {
	overflow-y: auto;
}
.p-dialog-content {
	height: 100%;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
	max-height: 300px;
	overflow-y: auto;
	max-width: 250px;
	overflow-x: hidden;
}

.p-toast-message-icon {
	flex-shrink: 0;
}

.p-inline-message {
	align-items: start !important;
}

.p-inline-messages {
	display: block;
	flex-shrink: 0;

	infocircleicon {
		flex-shrink: 0;
	}

 .p-message {
		// display: inline-flex;
		&.p-message-info {
			border: none;
		}

		.p-message-wrapper {
			padding: 0.5rem 0.5rem;
			align-items: start !important;
		}
	}
}

.p-inline-message > *:first-child {
	flex-shrink: 0;
	line-height: 1.5rem;
	display: inline;
}

.searchfield {
	width: 240px;
}

.card-stretch {
	display: block;
	height: 100%;
	.p-card {
		height: 100%;
	}
}

.p-card .p-card-content {
	padding: 0;
}

.p-virtualscroller-item {
	height: auto !important;
}

.radio-inline > *:not(:last-child){
	margin-right: 1rem;
}


.p-inputgroup .p-component:first-child {
	// border-top-left-radius: 0;
	// border-bottom-left-radius: 0;
}

p-password {
	width: 100%;
}

p-password input {
	border-radius: 0 0 0 0 !important;
}

input[required],
input[ng-reflect-required="true"],
textarea[required],
textarea[ng-reflect-required="true"],
p-dropdown[required] input.p-dropdown-label,
p-dropdown[ng-reflect-required="true"] input.p-dropdown-label {
	&::placeholder,
	&::-moz-placeholder,
	&:-moz-placeholder,
	&::-ms-input-placeholder,
	&::-webkit-input-placeholder {
		color: color('danger', 'text');
	}
}

input:required::placeholder,
textarea:required::placeholder {
	color: color('danger', 'text');
}

p-dropdown[required] .p-dropdown-label.p-placeholder,
p-dropdown[ng-reflect-required="true"] .p-dropdown-label.p-placeholder {
	color: color('danger', 'text');
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		border: 0;
		background: #fff;
		display: block;
		// z-index: 12310;
		height: 100%;
		width: calc(100% - 2.25rem);
		top: 0;
		left: 0;
		opacity: 0.5;
	}
}

.placeholder-left::placeholder {
	text-align: left;
}

.fake-input-text {
	display: block;
	width: 100%;
}

.fake-input-text:not(.edit) {
	border-top: 1px solid transparent !important;
	border-left: 1px solid transparent !important;
	border-right: 1px solid transparent !important;
}

.p-scrollpanel:active .p-scrollpanel-bar, .p-scrollpanel:hover .p-scrollpanel-bar {
	background-color: color('plain', 'bg') !important;

}
.p-scrollpanel-bar-y {
	background-color: color('secondary', 'transparent-bg') !important;
	opacity: 1 !important;
}

.pre-wrap {
	white-space: pre-wrap;
}

.tooltip-large {
	max-width: 20rem;
}

// [draggable=true],
.drag-handle {
	cursor: grab !important;
}

.drag-handle:active {
	cursor: grabbing !important;
}

fieldset {
	border: none;
	padding: 0;
	margin: 0;
}

.p-panel,
.p-fieldset {
	box-shadow: 0 2px 3px rgba(0,0,0,0.2);
}

.p-panel-title {
	order: inherit !important;
}

.p-tag {
	text-align: center;
}

.p-tag-disabled .p-tag {
	background: transparent !important;
	color: rgba(0,0,0,0.25) !important;
}

.text-xs {
	font-size: 0.75rem;
}

.p-breadcrumb ul li .p-menuitem-link {
	line-height: 1.75rem;
	max-width: 600px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.p-breadcrumb .last-breadcrumb {
	font-weight: bold;
}

.p-datepicker-monthpicker {
	width: auto !important;
	max-width: 16rem;
}

.date-picker-sm {
	max-width: 115px;
}

.p-divider.p-divider-horizontal {
	margin-top: 0;
}

.max-width-m {
	width: 200px;
	max-width: 200px;
	display: block;
}

.max-width-s {
	width: 150px;
	max-width: 150px;
	display: block;
}

p-toolbar {
	flex-shrink: 0;
	margin-bottom: 1.5rem; // mettre la même valeur que mb-4
}

.p-toolbar-group-left {
	flex-grow: 1;
}

.p-button.no-label .p-button-label{
	display: none;
}

.link {
	color: var(--primary-color);
}


.keyword {
	padding: 0 0.2rem;
	margin-right: 0.2rem;
	background: #E3F2FD;
	display: inline-block;
	border-radius: 3px;
}

.p-dragging {
	pointer-events: none;
}

.fieldset-no-legend {
	fieldset > legend {
		display: none;
	}
}

.no-box-shadow .p-fieldset {
	box-shadow: none !important;
}

.pi {
	display: inline;
	flex-shrink: 0;
}

.white-space-preline {
	white-space: pre-line;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

commentaire-bfa {
	a {
		color: var(--primary-color);
	}
}

.commentaire-fiche-nego {
	font-family: inherit;
	white-space: break-spaces;
	overflow: auto;
	max-width: 100%;
	width: 100%;
	max-height: 50rem;
}

.p-inputswitch-sm {
	height: 1.25rem;
	display: block;
}

.p-inputswitch-sm .p-inputswitch {
	height: 1.25rem;
	width: 2.5rem;
}

.p-inputswitch-sm .p-inputswitch .p-inputswitch-slider::before {
	background: #ffffff;
	width: 1rem;
	height: 1rem;
	margin: 0;
}

.p-inputswitch .p-inputswitch-slider::before {
	transform: translateX(-0.2rem) translateY(-0.5rem);
}

.p-inputswitch-sm .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
	transform: translateX(1rem) translateY(-0.5rem);
}

p-radiobutton[required]:not(.ng-valid) .p-radiobutton .p-radiobutton-box {
	border: 2px solid color('danger', 'bg');
	opacity: 0.5;
}

.message-excerpt {
	overflow-y: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
}

.p-toolbar {
	// align-items: start !important;
}

.absolute-full {
	@include absolute-full;
}

.inactive {
	color: #aaa;
}

p-inputotp input {
	width: 2rem !important;
}


.calendar-true-inline .p-calendar {
	display: flex;
}

.calendar-true-inline .p-monthpicker {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.calendar-true-inline .p-monthpicker-month {
	width: auto;
	flex-shrink: 0;
}


.p-multiselect-clear-icon {
	right: 1.75rem;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
	margin: 0 0.5rem 0 0.5rem;
}



.fieldset-simple .p-fieldset .p-fieldset-legend {
	border: none;
	background: none;
}

.fieldset-simple .p-fieldset-content {
	padding-bottom: 0;
}

.fieldset-simple .p-fieldset:not(.p-fieldset-expanded) {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.0);
}

@layer primeng {
	.no-padding-accordion .p-accordion-content {
		padding: 0 !important;
	}

	.no-padding-accordion .p-accordion-header .p-accordion-header-link {
		padding: 0 !important;
	}
}

.dimmer-on-hover:hover {
	filter: brightness(0.98);
}

.p-panelmenu-no-margin .p-panelmenu-panel {
	margin: 0;
}

.site-card-content {
	height: 100px;
}

.page-title {
	margin-bottom: 1rem !important;
	font-weight: 700 !important;
	font-size: 1.25rem !important;
	flex-shrink: 0;
}

.page-title-no-margin {
	font-weight: 700 !important;
	font-size: 1.25rem !important;
	flex-shrink: 0;
}

.accordion-transparent .p-accordion-header,
.accordion-transparent .p-accordion-header .p-accordion-header-link,
.accordion-transparent .p-accordion-content {
	background: transparent !important;
	border: none !important;
	padding: 0;
}

.accordion-transparent .p-accordion-header .p-accordion-header-link {
	padding-bottom: 0.5rem;
}

.top-1 {
	top: 1rem;
}

.top-2 {
	top: 2rem;
}

.p-editor-content {
	max-height: 500px;
	overflow: auto;
}
