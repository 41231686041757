

.mini-list {
	background: #fff;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.mini-list:not(.no-grid) {
	border: 1px solid #dee2e6;
	height: 150px;
	max-height: 150px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.mini-list:not(.no-grid) .mini-list-item {
	padding: 0.5rem 0.5rem;
	margin: 0;
	border: 0 none;
}

.mini-list:not(.no-grid) li.mini-list-item  {
	border-bottom: 1px solid rgba(0,0,0, 0.1);
}

.mini-list table {
	border-collapse: collapse;
	width: 100%;
}

.mini-list-item td {
	padding: 0.5rem;
	border: 1px solid rgba(0,0,0, 0.1);
	position: relative;
}
tr:first-of-type.mini-list-item td {
	border-top: none;
}

.mini-list-item td:first-of-type {
	padding-left: 1rem;
	border-left: none;
}

.mini-list-item td:last-of-type {
	padding-right: 0.5rem;
	border-right: none;
}

.mini-list-item .grow {
	width: 100%;
}


.p-picklist-list.p-picklist-source,
.p-picklist-list.p-picklist-target {
	max-height: 150px;
	overflow-y: auto;
}

