p-togglebutton.p-highlight .p-button::before {
    background: transparent !important;
    border-color: transparent !important;
}

.p-togglebutton.p-highlight .p-button-label {
    background: transparent !important;
    border-color: transparent !important;
}

.p-togglebutton.p-highlight:has(.p-button-icon) .p-button-icon {
    background: transparent;
}

.p-togglebutton.p-highlight:has(.p-button-icon) .p-button-label {
    background: transparent;
}

.p-fileupload .p-fileupload-buttonbar {
    display: flex;
    flex-direction: row;
}

@layer primeng {
    p-panel.no-header-panel .p-panel-header {
        display: none !important;
    }
}
